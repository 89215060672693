import React from "react";

const ContactPersonsSection = ({
    title,
    contactPersons
}) => {
    const getContactPerson = (personString) => {
        const splitted = personString.split(';');
        return {
            name: splitted[0] || '',
            title: splitted[1] || '',
            email: splitted[2] || '',
            phone: splitted[3] || '',
            imgSrc: splitted[4] || '',
        }
    };

    return (
        <section className="section bg-grey-1 is-flex is-flex-direction-column is-align-items-center standalone-country-page-contact-persons-section">
            <h3>{title}</h3>

            {contactPersons.map((s) => getContactPerson(s)).map((p, i) => (
                <article key={i}>
                    <img
                        src={p.imgSrc}
                        alt={p.name}
                        loading="lazy"
                    />

                    <div>
                        <p>
                            <span>Reach out to</span>
                            &nbsp;
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="12" viewBox="0 0 36 12" fill="none">
                                <path d="M36 6L26 0.226497V11.7735L36 6ZM27 5L0 5V7L27 7V5Z" fill="black" />
                            </svg>
                            &nbsp;
                            <span>{p.name},</span>
                            <br />
                            <span>{p.title}</span>
                        </p>

                        <address>
                            <a href={`mailto:${p.email}`}>
                                {p.email}
                            </a>
                            <a href={`tel:${p.phone}`}>
                                {p.phone}
                            </a>
                        </address>
                    </div>
                </article>
            ))}
        </section>
    );
};

export default ContactPersonsSection;