import React, { useEffect, useState } from "react";
import { graphql, navigate, Link } from "gatsby";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import { isBefore } from "date-fns";

import Layout from "../../components/layout/Layout";
import { WebinarVideosAndEventsSection } from "../../components/Event";
import ContactPersonsSection from "../../components/StandaloneCountryContactPersons";
import HubspotForm from "../../components/HubspotForm";

import { pushSAEvent, pushToGTM } from "../../helpers";

export default ({ data, location }) => {
    useEffect(() => {
        pushSAEvent(`visit_${language}_standalone_country_page`);
    }, []);

    const {
        language,
        pageTitle,
        seoDescription,
        heroImage,
        heroTitle,
        heroIngress,
        hubspotContactFormId,
        freeText,
        customerCasesTitle,
        customerCases,
        technicalMaterialLinks,
        eventsTitle,
        events,
        eventsPageSlug,
        webinarsTitle,
        webinars,
        contactPersonsTitle,
        contactPersons,
    } = data.contentfulFrontPageForIndividualCountries;

    const heroTitleParts = heroTitle.split(' ');

    return (
        <Layout
            location={location}
            title={pageTitle}
            description={seoDescription}
            language={language}
            seoData={{ image: getSrc(heroImage) }}
            transparentNavigation={true}
            pageContentId="standalone-country-page-content"
        >
            <main
                id="standalone-country-page-content"
                style={{ marginTop: "-5rem" }}
            >
                <HeroSection
                    coverImage={heroImage}
                    titlePart1={heroTitleParts.slice(0, 2).join(' ')}
                    titlePart2={heroTitleParts.slice(2).join(' ')}
                    description={heroIngress}
                />

                <FreeTextSection html={freeText.childMarkdownRemark.html} />

                <ContactFormSection hubspotContactFormId={hubspotContactFormId} />

                <CustomerCasesSection
                    title={customerCasesTitle}
                    customers={customerCases}
                />

                <TechnicalLinkSection html={technicalMaterialLinks.childMarkdownRemark.html} />

                <UpcomingEventsSection
                    title={eventsTitle}
                    events={events}
                    seeMoreLinkTo={`/${language}/${eventsPageSlug}`}
                />

                <WebinarsSection
                    title={webinarsTitle}
                    webinars={webinars}
                />

                <ContactPersonsSection
                    title={contactPersonsTitle}
                    contactPersons={contactPersons}
                />
            </main>
        </Layout>
    );
};

const HeroSection = ({
    coverImage,
    titlePart1,
    titlePart2,
    description
}) => (
    <section className="hero is-fullheight">
        <GatsbyImage
            image={getImage(coverImage)}
            alt=""
            style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                left: "0"
            }}
        />

        <div className="hero-body">
            <div
                className="container has-text-centered"
                style={{ maxWidth: '800px' }}
            >
                <h1 className="title size-100 is-w margin-bottom-0">
                    <span className="is-white font-variable"
                        style={{ lineHeight: '1' }}
                    >
                        {titlePart1}
                    </span>
                    <br />
                    <span className="is-white font-bold is-transparent text-stroke-white"
                        style={{ lineHeight: '1' }}
                    >
                        {titlePart2}
                    </span>
                </h1>

                <div className="is-flex is-justify-content-center margin-top-60 is-half is-offset-one-quarter">
                    <p className="is-white font-regular size-40 margin-bottom-10"
                        style={{ maxWidth: '550px', lineHeight: '1.25' }}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </div>
            </div>
        </div>
    </section>
);

const FreeTextSection = ({ html }) => (
    <section className="section is-flex is-justify-content-center standalone-country-page-free-text-section">
        <div dangerouslySetInnerHTML={{ __html: html }} />
    </section>
);

const ContactFormSection = ({ hubspotContactFormId }) => {
    const handleContactFormSubmit = () => {
        pushToGTM({
            'event': 'frends_form_submit',
            'form_type': 'Contact Form Submit',
            'resource_name': 'Standalone country page contact form'
        });

        navigate("/thanks-for-contacting-us/");
    };

    return (
        <section className="section is-flex is-justify-content-center">
            <div style={{ width: '100%', maxWidth: '590px' }}>
                <HubspotForm
                    formId={hubspotContactFormId}
                    loading={<div>Loading...</div>}
                    onSubmit={handleContactFormSubmit}
                />
            </div>
        </section>
    );
};

const CustomerCasesSection = ({
    title,
    customers,
}) => {
    const getCustomerLink = (customer) => {
        if (customer.internal?.type === 'ContentfulLink') {
            return <>
                <a
                    href={customer.url}
                    target="_blank"
                >
                    <p>
                        <span>{customer.title}</span>
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="9" viewBox="0 0 36 9" fill="none">
                        <path d="M35.9996 4.38336L28.4711 0.10311L28.5285 8.76317L35.9996 4.38336ZM1.00538 5.36564L29.2548 5.17815L29.2448 3.67818L0.995426 3.86568L1.00538 5.36564Z" fill="#58696C" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="13" viewBox="0 0 56 13" fill="none">
                        <path d="M55.9994 6.31699L45.9613 0.609988L46.0379 12.1567L55.9994 6.31699ZM1.00724 7.68201L47.0062 7.3767L46.993 5.37675L0.993965 5.68205L1.00724 7.68201Z" fill="black" />
                    </svg>
                </a>

                <a
                    href={customer.url}
                    target="_blank"
                >
                    <p>{customer.title}</p>
                    <p></p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="13" viewBox="0 0 56 13" fill="none">
                        <path d="M55.9995 6.31699L45.9614 0.609988L46.0381 12.1567L55.9995 6.31699ZM1.00736 7.68201L47.0063 7.3767L46.9931 5.37675L0.994087 5.68205L1.00736 7.68201Z" fill="#58696C" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="93" height="12" viewBox="0 0 93 12" fill="none">
                        <path d="M92.25 5.8125L82.2579 0.0253415L82.2421 11.5723L92.25 5.8125ZM0.748634 6.6875L83.2486 6.8002L83.2514 4.80021L0.751366 4.6875L0.748634 6.6875Z" fill="black" />
                    </svg>
                </a>
            </>
        }

        return <>
            <Link
                title={customer.name || customer.title}
                to={customer.slug ? `/customers/${customer.slug}` : `/article/${customer.articleUrl}`}
            >
                <p>
                    <span>{customer.name || customer.title}</span>
                    <span>{customer.category}</span>
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="9" viewBox="0 0 36 9" fill="none">
                    <path d="M35.9996 4.38336L28.4711 0.10311L28.5285 8.76317L35.9996 4.38336ZM1.00538 5.36564L29.2548 5.17815L29.2448 3.67818L0.995426 3.86568L1.00538 5.36564Z" fill="#58696C" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="13" viewBox="0 0 56 13" fill="none">
                    <path d="M55.9994 6.31699L45.9613 0.609988L46.0379 12.1567L55.9994 6.31699ZM1.00724 7.68201L47.0062 7.3767L46.993 5.37675L0.993965 5.68205L1.00724 7.68201Z" fill="black" />
                </svg>
            </Link>

            <Link
                title={customer.name || customer.title}
                to={customer.slug ? `/customers/${customer.slug}` : `/article/${customer.articleUrl}`}
            >
                <p>{customer.name || customer.title}</p>
                <p>{customer.category}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="13" viewBox="0 0 56 13" fill="none">
                    <path d="M55.9995 6.31699L45.9614 0.609988L46.0381 12.1567L55.9995 6.31699ZM1.00736 7.68201L47.0063 7.3767L46.9931 5.37675L0.994087 5.68205L1.00736 7.68201Z" fill="#58696C" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="93" height="12" viewBox="0 0 93 12" fill="none">
                    <path d="M92.25 5.8125L82.2579 0.0253415L82.2421 11.5723L92.25 5.8125ZM0.748634 6.6875L83.2486 6.8002L83.2514 4.80021L0.751366 4.6875L0.748634 6.6875Z" fill="black" />
                </svg>
            </Link>
        </>
    }

    return (
        <section
            id="standalone-country-page-customer-cases"
            className="section is-flex is-justify-content-center standalone-country-page-customer-cases-section"
        >
            <div>
                <h2>{title}</h2>

                <ul>
                    {customers.map((customer, i) => (
                        <li key={i}>
                            {getCustomerLink(customer)}
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

const TechnicalLinkSection = ({ html }) => (
    <section className="section standalone-country-page-technical-links-section">
        <div
            className="container"
            dangerouslySetInnerHTML={{ __html: html }}
        />
    </section>
);

const UpcomingEventsSection = ({
    title,
    events,
    seeMoreLinkTo,
}) => {
    const upcomingEvents = events
        .filter((e) => isBefore(new Date(), new Date(e.startTime)))
        .sort((a, b) => new Date(b.startTime) - new Date(a.startTime));

    return (
        <WebinarVideosAndEventsSection
            title={title}
            items={upcomingEvents}
            numberOfTotalItems={upcomingEvents.length}
            seeMoreLinkTo={seeMoreLinkTo}
        />
    );
};

const WebinarsSection = ({
    title,
    webinars,
}) => {
    const sorterWebinars = webinars.slice().sort((a, b) => new Date(b.webinarStartTime) - new Date(a.webinarStartTime));
    const [numberOfShownWebinars, setNumberOfShownWebinars] = useState(6);

    return (
        <WebinarVideosAndEventsSection
            title={title}
            items={sorterWebinars.slice(0, numberOfShownWebinars)}
            numberOfTotalItems={sorterWebinars.length}
            onLoadMoreButtonClick={() => setNumberOfShownWebinars((cur) => cur + 6)}
        />
    );
};

export const query = graphql`query ($contentful_id: String!) {
    contentfulFrontPageForIndividualCountries(contentful_id: { eq: $contentful_id }) {
        pageTitle
        language
        seoDescription
        heroTitle
        heroIngress
        heroImage {
            gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
        }
        hubspotContactFormId
        freeText {
            childMarkdownRemark {
                html
            }
        }
        customerCasesTitle
        customerCases {
            ... on ContentfulCustomer {
                slug
                name
                category
            }
            ... on ContentfulArticle {
                articleUrl
                title
            }
            ... on ContentfulLink {
                title
                url
                internal {
                    type
                  }
            }
        }
        technicalMaterialLinks {
            childMarkdownRemark {
                html
            }
        }
        eventsTitle
        events {
            title
            text
            url
            startTime
            endTime
            location
            speakers {
                name
                title
                portrait {
                    gatsbyImageData(width: 90, height: 90, quality: 100)
                }
            }
            image {
                gatsbyImageData(quality: 100, width: 800)
            }
        }
        eventsPageSlug
        webinarsTitle
        webinars {
            title
            webinarUrl
            webinarStartTime
            webinarLanguage
            webinarKeywords
            webinarEndTime
            webinarSpeakers {
                name
                title
                portrait {
                    gatsbyImageData(width: 90, height: 90, quality: 100)
                }
            }
            webinarImage {
                gatsbyImageData(quality: 100, width: 800)
            }
        }
        contactPersonsTitle
        contactPersons
    }
}`;